export function GET_ARCHIVE (state) {
  state.archive.fetching = true
  state.archive.fetched = false
}

export function RECEIVE_ARCHIVE (state, archive) {
  state.archive.fetching = false
  state.archive.fetched = true
  state.archive.data = archive
}
