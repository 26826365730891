export function STORE_QUEST (state) {
  state.quest.submitting = true
  state.quest.submitted = false
}

export function QUEST_STORED (state, quest) {
  state.quest.submitting = false
  state.quest.submitted = true
  state.quest.data = quest
}

export function GET_QUEST (state) {
  state.quest.fetching = true
  state.quest.fetched = false
  state.quest.data = null
}

export function RECEIVE_QUEST (state, quest) {
  state.quest.fetching = false
  state.quest.fetched = true
  state.quest.data = quest
}

export function QUEST_ERROR (state, err) {
  if (state.quest.submitting) state.quest.submitting = false
  if (state.quest.fetching) state.quest.fetching = false
  state.error = err
}

export function FINISH_QUEST (state) {
  state.quest.submitting = true
  state.quest.sumitted = false
}

export function QUEST_FINISHED (state) {
  state.quest.submitting = false
  state.quest.sumitted = true
  state.quest.data = null
}
