export function STORE_NEWS (state) {
  state.news.submitting = true
  state.news.submitted = false
}

export function NEWS_STORED (state) {
  state.news.submitting = false
  state.news.submitted = true
}

export function GET_NEWS (state) {
  state.news.fetching = true
  state.news.fetched = false
}

export function RECEIVE_STORED (state, { news, start }) {
  state.news.fetching = false
  state.news.fetched = true

  if (start === 0 || !state.news.data) {
    state.news.data = news
  } else {
    state.news.data.has_more = news.has_more
    state.news.data.quota_max = news.quota_max
    state.news.data.quota_remaining = news.quota_remaining
    state.news.data.items.push(...news.items)
  }
}

export function NEWS_ERROR (state) {
  if (state.news.submitting) state.news.submitting = false
  if (state.news.fetching) state.news.fetching = false
}
