export function events (state) {
  return state.events && state.events.data && state.events.data.items ? state.events.data.items : []
}

export function hasMore (state) {
  return state.events && state.events.data && state.events.data && state.events.data.has_more
}

export function isSubmitting (state) {
  return state.events && state.events.submitting && !state.events.submitted
}

export function isLoading (state) {
  return state.events && state.events.fetching && !state.events.fetched
}
