export function GET_ONBOARDINGS (state) {
  state.onboardings.fetching = true
  state.onboardings.fetched = false
}

export function RECEIVE_ONBOARDINGS (state, onboardings) {
  state.onboardings.fetching = false
  state.onboardings.fetched = true
  state.onboardings.data = onboardings
}

export function ONBOARDINGS_ERROR (state, err) {
  if (state.onboardings.submitting) state.onboardings.submitting = false
  if (state.onboardings.fetching) state.onboardings.fetching = false
}
