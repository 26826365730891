export function GET_STATION (state) {
  state.station.fetching = true
  state.station.fetched = false
  state.station.data = null
  state.error = null
}

export function RECEIVE_STATION (state, station) {
  state.station.fetching = false
  state.station.fetched = true
  state.station.data = station
}

export function ACTIVE_STATION (state) {
  state.station.submitting = true
  state.station.submitted = false
  state.error = null
}

export function STATION_ACTIVATED (state) {
  state.station.submitting = false
  state.station.submitted = true
}

export function GET_STATIONS (state) {
  state.stations.fetching = true
  state.stations.fetched = false
  state.error = null
}

export function RECEIVE_STATIONS (state, station) {
  state.stations.fetching = false
  state.stations.fetched = true
  state.stations.data = station
}

export function STATION_ERROR (state, err) {
  if (state.station.fetching) state.station.fetching = false
  if (state.station.submitting) state.station.submitting = false
  state.error = err
}
