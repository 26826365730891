import axios from 'axios'

export function getSekundarstufen ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('GET_SEKUNDARSTUFEN')
    axios.get('/api/identity/v1/schools/sekundarstufen')
      .then((response) => {
        commit('RECEIVE_SEKUNDARSTUFEN', response.data)
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
