export function GET_NOTIFICATIONS (state) {
  state.notifications.fetching = true
  state.notifications.fetched = false
  state.error = null
}

export function RECEIVE_NOTIFICATIONS (state, notifications) {
  state.notifications.fetching = false
  state.notifications.fetched = true
  state.notifications.data = notifications
}
export function NOTIFICATIONS_ERROR (state, err) {
  if (state.notifications.submitting) state.notifications.submitting = false
  if (state.notifications.fetching) state.notifications.fetching = false
  state.error = err
}
