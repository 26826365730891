import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from '@/i18n/messages/de.json'
import { de as deNavigation } from '@sunzinet-org/bwp-global-navigation'
import axios from 'axios'
import { merge } from 'lodash'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'de', // set locale
  messages: {
    de: merge(deNavigation, de) // set locale messages
  }
})

const loadedLanguages = ['de'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ '@/i18n/messages/' + lang + '.json').then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}
