export function quest (state) {
  return state.quest && state.quest.data && state.quest.data.uuid ? state.quest.data : null
}

export function isSubmitting (state) {
  return state.quest && state.quest.submitting && !state.quest.submitted
}

export function isLoading (state) {
  return state.quest && state.quest.fetching && !state.quest.fetched
}
