<template>
  <Modal @close-parent="handleClose">
    <template #default="{ close }">
      <div class="modal-card">
        <header class="modal-card-head" />
        <section class="modal-card-body" data-qa="school-data-modal">
          <p class="heading is-large">
            {{ title }}
          </p>
          <SchoolFormSmall
            ref="schoolFormSmall"
            :school-uuid="schoolUuid"
            :initial-school-data="schoolData"
            @saved="close(null, handleSaved)"
          />
        </section>
        <footer class="modal-card-foot">
          <button
            type="submit"
            data-qa="school-form-small-submit"
            class="button is-primary-gradient is-rounded is-pulled-right"
            title="Schule speichern"
            @click="$refs.schoolFormSmall.submit()"
          >
            {{ $t('cta.speichern') }}
            <i class="fas fa-arrow-right" aria-hidden="true" />
          </button>
        </footer>
      </div>
    </template>
  </Modal>
</template>
<script>
import SchoolFormSmall from '@/components/schools/SchoolFormSmall.vue'

export default {
  name: 'SchoolDataModal',
  components: { SchoolFormSmall },
  props: {
    title: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    schoolData: {
      type: Object,
      required: true
    },
    schoolUuid: {
      type: String,
      required: true
    }
  },
  methods: {
    handleSaved () {
      this.$emit('saved')
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>
