<template>
  <div id="app" ref="app">
    <div class="container">
      <OfflineBanner :class="{ 'main-content': showMenu}" />
      <ImportantNotificationBanner
        v-if="isAdminSchool"
      />
      <UpdateServiceWorkerModal v-if="showMessages" />
      <div class="columns">
        <div v-if="showMenu" class="column is-12-mobile is-3-tablet is-3-desktop">
          <div class="columns">
            <div id="heading" class="column is-12 is-10-desktop p-0">
              <Menu
                app="dashboard"
                :current-user="user"
                :user-groups="userGroups"
                :current-role="userRole"
                :ranking="gamificationModule.ranking.data"
                :client="client"
                :keycloak="$keycloak"
                @turnOnOnboardings="turnOnOnboardings"
                @roleChange="loadMe"
              />
            </div>
          </div>
        </div>
        <div class="column" :class="{'is-12-mobile is-9-tablet is-9-desktop': showMenu}">
          <div class="columns">
            <main
              id="main"
              class="column is-12"
              :class="{'main-content' : !isOffline, 'mt-0' : !isOffline && !showMenu}"
              role="main"
            >
              <router-view :key="$route.fullPath" />
            </main>
          </div>
        </div>
      </div>
    </div>
    <portal-target name="modal" />
    <Messages v-if="showMessages" />
    <ExternalLinkProceed />
    <Onboarding
      v-if="steps"
      :key="onboarding"
      :steps="steps"
      :options="{debug: true, highlight: true, startTimeout: 0}"
    />
    <OnboardingModal
      v-if="showMessages && showOnboardingModal"
      :user-role="userRole"
      @close="showOnboardingModal = false"
      @turnOnOnboardings="turnOnOnboardings"
    />
    <TermConditionModal
      v-if="showMessages && user && !isStudent"
      :user="user"
      :is-student="isStudent"
      :is-bund-admin="isAdminBund"
      :keycloak="$keycloak"
    />
    <SchoolDataModal
      v-if="showSchoolDataModal"
      :button-text="$t('cta.speichern')"
      title="Nachpflege Daten"
      :school-data="schoolData"
      :school-uuid="user.school.uuid || ''"
      @saved="showSchoolDataModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import roles from '@/store/usersModule/roles.js'
import ImportantNotificationBanner from '@sunzinet-org/bwp-global-components/src/components/internal-important-notification/ImportantNotificationBanner.vue'
import UpdateServiceWorkerModal from '@sunzinet-org/bwp-global-components/src/components/modal/UpdateServiceWorkerModal.vue'
import SchoolDataModal from '@/components/schools/SchoolDataModal.vue'

export default {
  name: 'App',
  components: {
    SchoolDataModal,
    ImportantNotificationBanner,
    UpdateServiceWorkerModal
  },

  data () {
    return {
      showOnboardingModal: false,
      showMessages: false,
      onboarding: 0,
      isOffline: false,
      showSchoolDataModal: false
    }
  },

  computed: {
    ...mapState([
      'usersModule',
      'gamificationModule',
      'clientsModule'
    ]),

    ...mapGetters({
      steps: 'cmsModule/steps',
      userRole: 'usersModule/getCurrentRole',
      isStudent: 'usersModule/isStudent',
      isAdminBund: 'usersModule/isSuperAdmin',
      isAdminSchool: 'usersModule/isSchoolOrLand'
    }),

    showMenu () {
      return this.user && this.userRole !== roles.DEACTIVATED && this.$router.currentRoute.path !== '/logout'
    },

    userGroups () {
      return this.usersModule.me.fetched && this.usersModule.me.roles ? this.usersModule.me.roles : null
    },

    user () {
      if (this.usersModule.me.fetched && this.usersModule.me.data) {
        this.setTheme()
        if (this.usersModule.me.data.show_onboarding && this.showMessages) {
          this.openOnboardingModal()
        }
        return this.usersModule.me.data
      }
      return null
    },
    client () {
      return this.clientsModule.client?.fetched && this.clientsModule.client.data ? this.clientsModule.client.data : null
    },
    schoolData () {
      return {
        data_protection_officer_email: this.user.school.data_protection_officer_email,
        data_protection_officer_first_name: this.user.school.data_protection_officer_first_name,
        data_protection_officer_last_name: this.user.school.data_protection_officer_last_name,
        data_protection_officer_phone: this.user.school.data_protection_officer_phone,
        principal_email: this.user.school.principal_email,
        principal_first_name: this.user.school.principal_first_name,
        principal_last_name: this.user.school.principal_last_name
      }
    }
  },
  mounted () {
    window.addEventListener('offline', this.updateOfflineStatus)
    window.addEventListener('online', this.updateOfflineStatus)
    if (window.location.pathname === '/logout') return
    this.checkIfShowMessages()
    this.loadMe()
      .then(() => {
        this.initMatomo()
        if (this.userGroups.includes(roles.STUDENT)) {
          this.loadRanking()
          this.checkReminders()
          this.checkReviews()
        }
        if (this.userGroups.includes(roles.SCHOOL_ADMIN) && (this.user.school.data_protection_officer_email === '' || this.user.school.principal_email === '')) {
          this.showSchoolDataModal = true
        }
        if (this.user.client_uuid) {
          this.getClient(this.user.client_uuid)
        }
        // if (window._paq) {
        //  window._paq.push(['setVisitorCookieTimeout', 604800])
        // }
      })
  },
  beforeDestroy () {
    window.removeEventListener('offline', this.updateOfflineStatus)
    window.removeEventListener('online', this.updateOfflineStatus)
  },
  methods: {
    ...mapActions({
      loadStatus: 'usersModule/status',
      loadMe: 'usersModule/me',
      loadRanking: 'gamificationModule/getRanking',
      getClient: 'clientsModule/getByID',
      getOnboardings: 'cmsModule/getOnboardings'
    }),
    updateOfflineStatus () {
      this.isOffline = !window.navigator.onLine
    },
    checkIfShowMessages () {
      this.showMessages = sessionStorage.getItem('messagesManagement') || !sessionStorage.getItem('cypress')
    },
    openOnboardingModal () {
      this.showOnboardingModal = (this.userRole !== roles.DEACTIVATED && this.userRole !== roles.TECHNICAL_ADMIN)
    },

    turnOnOnboardings () {
      if (!this.steps) this.getOnboardings(window.Cypress ? 'E2E' : 'COCKPIT')
      this.onboarding++
      // Navigation close on mobile
      if (window.innerWidth <= 768) {
        document.querySelector('[data-onboarding="mobile-navigation-open"]').click()
      }
    },

    setTheme () {
      const file = document.getElementById('theme') || document.createElement('link')

      if (this.usersModule.me.data.contrast_mode === false) {
        if (this.usersModule.me.data.theme != null && this.usersModule.me.data.theme !== 'original' && this.usersModule.me.data.theme !== '') {
          file.rel = 'stylesheet'
          file.id = 'theme'
          file.href = `/styleguide/src/css/bwp-ui${this.usersModule.me.data.theme}.css`
          document.head.appendChild(file)
        }
      } else {
        file.rel = 'stylesheet'
        file.id = 'theme'
        file.href = '/styleguide/src/css/bwp-ui-contrast.css'
        document.head.appendChild(file)
      }
    },

    checkReminders () {
      axios.get('/api/arcade/v1/reminders')
    },

    checkReviews () {
      axios.get('/api/arcade/v1/reviews')
    },

    initMatomo () {
      if (this.user.tracking_consent && this.$matomo) {
        const _mtm = window._mtm = window._mtm || []
        _mtm.push({ 'mtm.startTime': (new Date().getTime()), event: 'mtm.Start' });
        (function (host) {
          const d = document; const g = d.createElement('script'); const s = d.getElementsByTagName('script')[0]
          g.async = true; g.src = host; s.parentNode.insertBefore(g, s)
        })(this.$matomo.host)
        // add data-client to div with id app
        this.$refs.app.setAttribute('data-client', this.user.client_uuid)
        this.$refs.app.setAttribute('data-school', this.user.school.uuid ? this.user.school.uuid : '')
        this.$refs.app.setAttribute('data-class', this.user.class ? this.user.class.uuid : '')
      }
    }
  }
}
</script>

<style>
</style>
