import Vue from 'vue'
import VueRouter from 'vue-router'
import { loadLanguageAsync } from '@/i18n'
import uroles from '@/store/usersModule/roles'
import store from '../store'

Vue.use(VueRouter)

function requireRole (to, from, next, roles = []) {
  function proceed () {
    if (store.getters['usersModule/getUserLoadStatus']()) {
      const userRoles = store.getters['usersModule/getUserGroups'].split(',') || []
      if (userRoles.some(userRole => roles.includes(userRole))) {
        next()
      } else {
        if (userRoles.includes(uroles.DEACTIVATED)) {
          next({ name: 'Landingpage' })
        } else {
          next({ path: '/' })
        }
      }
    }
  }
  if (!store.getters['usersModule/getUserLoadStatus']()) {
    if (!store.getters['usersModule/getUserLoadingStatus']()) {
      store.dispatch('usersModule/me')
    }

    store.watch(store.getters['usersModule/getUserLoadStatus'], function () {
      if (store.getters['usersModule/getUserLoadStatus']()) {
        proceed()
      }
    })
  } else {
    proceed()
  }
}

const routes = [
  {
    path: '/data-protection',
    name: 'DataProtection',
    component: () => import(/* webpackChunkName: "DataProtection" */ '../views/static/DataProtection.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/static/Imprint.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/gebaerdensprache',
    name: 'Signlanguage',
    component: () => import(/* webpackChunkName: "SignLanguage" */ '../views/static/SignLanguage.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/sehbeeintraechtigte',
    name: 'SightImpaired',
    component: () => import(/* webpackChunkName: "SightImpaired" */ '../views/static/SightImparied.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/landingpage',
    name: 'Landingpage',
    component: () => import(/* webpackChunkName: "Landingpage" */ '../views/landingpage/Landingpage.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.DEACTIVATED]),
    meta: {
      roles: [uroles.DEACTIVATED]
    }
  },
  {
    path: '/barrier-free-info',
    name: 'BarrierFreeInfo',
    component: () => import(/* webpackChunkName: "BarrierFreeInfo" */ '../views/barrierFreeInfo/BarrierFreeInfo.vue'),
    beforeEnter: (to, from, next) => requireRole(to, from, next, [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]),
    meta: {
      roles: [uroles.SUPER_ADMIN, uroles.LAND_ADMIN, uroles.SCHOOL_ADMIN, uroles.TEACHER, uroles.STUDENT, uroles.TECHNICAL_ADMIN]
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/logout/Logout.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/errors/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const lang = to.params.lang ? to.params.lang : 'de'
  loadLanguageAsync(lang).then(() => next())
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

export default router
