import axios from 'axios'

export function getDeadlines ({ commit }, target) {
  return new Promise((resolve, reject) => {
    commit('GET_DEADLINES')
    axios.get('/api/signatures/v1/signature/principal-deadline')
      .then(response => {
        commit('RECEIVE_DEADLINES', response.data)
        resolve()
      }).catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('DEADLINES_ERROR', msg)
        reject(err)
      })
  })
}

export function getMyStatus ({ commit }) {
  commit('GET_MY_STATUS')
  axios.get('/api/signatures/v1/signature/status/my')
    .then((response) => {
      commit('RECEIVE_MY_STATUS', response.data)
    })
    .catch((err) => {
      console.log(err)
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('SIGNATURES_ERROR', msg)
    })
}
