export function GET_SEKUNDARSTUFEN (state) {
  state.sekundarstufen.fetching = true
  state.sekundarstufen.fetched = false
  state.error = null
}

export function RECEIVE_SEKUNDARSTUFEN (state, sekundarstufen) {
  state.sekundarstufen.fetching = false
  state.sekundarstufen.fetched = true
  state.sekundarstufen.data = sekundarstufen
}
