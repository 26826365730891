import axios from 'axios'

export function getMyway ({ commit }) {
  commit('GET_WAY')
  axios.get('/api/bo/v1/career-orientations/my')
    .then(response => {
      commit('RECEIVE_WAY', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATIONS_ERROR', msg)
    })
}

export function getMyStations ({ commit }) {
  commit('GET_MY_STATIONS')
  axios.get('/api/bo/v1/career-orientations/my/stations')
    .then(response => {
      commit('RECEIVE_MY_STATIONS', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATIONS_ERROR', msg)
    })
}
