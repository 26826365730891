import axios from 'axios'

export function getRanking ({ commit }) {
  commit('GET_RANKING')
  axios.get('/api/arcade/v1/rankings')
    .then((response) => {
      commit('RECEIVE_RANKING', response.data)
    })
}

export function getAchievements ({ commit }) {
  commit('GET_ACHIEVEMENTS')
  axios.get('/api/arcade/v1/achievements')
    .then((response) => {
      commit('RECEIVE_ACHIEVEMENTS', response.data)
    })
}
