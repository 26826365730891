import axios from 'axios'

export function getArchive ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('GET_ARCHIVE')
    axios.get('/api/identity/v1/archive', {
      responseType: 'arraybuffer',
      'Content-Type': 'application/zip'
    })
      .then((response) => {
        commit('RECEIVE_ARCHIVE', response.data)
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}
