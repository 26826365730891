export const ADMIN = 'ADMIN'
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const TEACHER = 'LEHRER'
export const STUDENT = 'SCHUELER'
export const SCHOOL_ADMIN = 'ADMIN_SCHULE'
export const LAND_ADMIN = 'ADMIN_LAND'
export const DEACTIVATED = 'DEACTIVATED'
export const TECHNICAL_ADMIN = 'TECHNICAL_ADMIN'
export const ROLES = [ADMIN, SUPER_ADMIN, TEACHER, SCHOOL_ADMIN, LAND_ADMIN, STUDENT, DEACTIVATED, TECHNICAL_ADMIN]

export default {
  ADMIN,
  TEACHER,
  SUPER_ADMIN,
  SCHOOL_ADMIN,
  LAND_ADMIN,
  STUDENT,
  DEACTIVATED,
  TECHNICAL_ADMIN
}

export function hasCorrectRoles (userGroups, roles = []) {
  return userGroups && roles.some(role => userGroups.indexOf(role) !== -1)
}
