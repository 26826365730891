export function news (state) {
  return state.news && state.news.data && state.news.data.items ? state.news.data.items : []
}

export function isMore (state) {
  return state.news && state.news.data && state.news.data && state.news.data.has_more
}

export function isSubmiting (state) {
  return state.news && state.news.submitting && !state.news.submitted
}

export function isLoading (state) {
  return state.news && state.news.fetching && !state.news.fetched
}
