import roles, { ROLES } from './roles'

export function getUserLoadStatus (state) {
  return () => state.me.fetched
}

export function getUserLoadingStatus (state) {
  return () => state.me.fetching
}

export function getUserGroups (state) {
  return state.me.data ? state.me.data.user_groups : null
}

export function getTeacherClasses (state) {
  return state.me.data && state.me.data.classes ? state.me.data.classes : []
}

export function hasAdminRole (state) {
  return state.me && state.me.data ? state.me.data.user_groups.indexOf(roles.ADMIN) !== -1 : false
}

export function isSchool (state) {
  return state.me && state.me.data ? state.me.data.user_groups.indexOf(roles.SCHOOL_ADMIN) !== -1 : false
}

export function isTeacher (state) {
  return state.me && state.me.data ? state.me.data.user_groups.indexOf(roles.TEACHER) !== -1 : false
}

export function isStudent (state) {
  return state.me && state.me.data ? state.me.data.user_groups.indexOf(roles.STUDENT) !== -1 : false
}

export function isSuperAdmin (state) {
  return state.me && state.me.data ? state.me.data.user_groups.indexOf(roles.SUPER_ADMIN) !== -1 : false
}
export function isSchoolOrLand (state) {
  return state.me && state.me.data ? (state.me.data.user_groups.indexOf(roles.LAND_ADMIN) !== -1 || state.me.data.user_groups.indexOf(roles.SCHOOL_ADMIN) !== -1) : false
}

export function isTechnicalAdmin (state) {
  return state?.me?.data ? state.me.data.user_groups.indexOf(roles.TECHNICAL_ADMIN) : false
}

export function user (state) {
  return state.me && state.me.data ? state.me.data : undefined
}

export function userSchoolHasSekundarstufe2 (state) {
  return state.me && state.me.data && state.me.data.school ? state.me.data.school.has_sekundarstufe2 : false
}

export function showOnboarding (state) {
  // eslint-disable-next-line camelcase
  return state.me?.data?.show_onboarding
}

export function userUuid (state) {
  return state.me.data ? state.me.data.uuid : null
}

export function getCurrentRole (state) {
  // eslint-disable-next-line camelcase
  const userGroups = state?.me?.data?.user_groups
  let currentRole = null
  if (!userGroups) return
  currentRole = userGroups

  if (userGroups.includes(',')) {
    const groups = userGroups.split(',')
    currentRole = groups.find((role) => {
      if (ROLES.includes(role)) return role
      return null
    })
  }

  return currentRole
}
