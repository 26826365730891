export default {
  me: {
    fetching: false,
    fetched: false,
    submitting: false,
    submitted: false,
    data: null,
    roles: undefined
  },
  error: null
}
