import axios from 'axios'

export function getByID ({ commit }, uuid) {
  return new Promise((resolve, reject) => {
    commit('GET_CLIENT')
    axios.get(`/api/identity/v1/clients/${uuid}`)
      .then((response) => {
        commit('RECEIVE_CLIENT', response.data)
        resolve()
      })
      .catch((err) => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('CLIENTS_ERROR', msg)
        reject(err)
      })
  })
}
