export function GET_CLIENT (state) {
  state.client.fetching = true
  state.client.fetched = false
}

export function RECEIVE_CLIENT (state, client) {
  state.client.fetching = false
  state.client.fetched = true
  state.client.data = client
}

export function CLIENTS_ERROR (state, err) {
  if (state.client.fetching) state.client.fetching = false
  state.error = err
}
