export function GET_DEADLINES (state) {
  state.deadlines.fetching = true
  state.deadlines.fetched = false
}

export function RECEIVE_DEADLINES (state, deadlines) {
  state.deadlines.fetching = false
  state.deadlines.fetched = true
  state.deadlines.data = deadlines
}

export function DEADLINES_ERROR (state, err) {
  if (state.deadlines.submitting) state.deadlines.submitting = false
  if (state.deadlines.fetching) state.deadlines.fetching = false
}

export function GET_MY_STATUS (state) {
  state.status.fetching = true
  state.status.fetched = false
}

export function RECEIVE_MY_STATUS (state, status) {
  state.status.fetching = false
  state.status.fetched = true
  state.status.data = status
}

export function SIGNATURES_ERROR (state, err) {
  if (state.status.fetching) state.status.fetching = false
  this.state.errors.push(err)
}
