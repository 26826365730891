import axios from 'axios'

export function getOnboardings ({ commit }, target) {
  return new Promise((resolve, reject) => {
    commit('GET_ONBOARDINGS')
    axios.get(`/api/cms/v1/onboardings/${target}`)
      .then(response => {
        commit('RECEIVE_ONBOARDINGS', response.data)
        resolve()
      }).catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('ONBOARDINGS_ERROR', msg)
        reject(err)
      })
  })
}
