import axios from 'axios'

export function storeQuest ({ commit }, quest) {
  return new Promise((resolve, reject) => {
    commit('STORE_QUEST')
    axios.post('/api/arcade/v1/quests', quest)
      .then(response => {
        commit('QUEST_STORED', response.data)
        resolve()
      }).catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('QUEST_ERROR', msg)
        reject(err)
      })
  })
}

export function getQuest ({ commit }, classUuid) {
  commit('GET_QUEST')
  axios.get(`/api/arcade/v1/quests?class_uuid=${classUuid}`)
    .then(response => {
      commit('RECEIVE_QUEST', response.data)
    }).catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('QUEST_ERROR', msg)
    })
}

export function finishQuest ({ commit }, uuid) {
  return new Promise((resolve, reject) => {
    commit('FINISH_QUEST')
    axios.put(`/api/arcade/v1/quests/${uuid}`)
      .then(() => {
        commit('QUEST_FINISHED')
        resolve()
      })
      .catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('QUEST_ERROR', msg)
        reject(err)
      })
  })
}
