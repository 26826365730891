export default {
  myway: {
    fetching: false,
    fetched: false,
    data: null
  },
  stations: {
    fetching: false,
    fetched: false,
    data: null
  },
  error: null
}
