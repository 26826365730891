export default {
  stations: {
    fetching: false,
    fetched: false,
    submitting: false,
    submitted: false,
    data: null
  },
  station: {
    fetching: false,
    fetched: false,
    submitting: false,
    submitted: false,
    data: null
  },
  error: null
}
