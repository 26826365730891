import * as types from './types'

export function getNotifications (state) {
  const notifications = {
    [types.NEWS]: 0,
    [types.WEG]: 0
  }
  if (state.notifications.data) {
    state.notifications.data.forEach(notification => {
      if (notification.type === types.WEG) {
        notifications[types.WEG] = notification.amount
      } else {
        notifications[types.NEWS] += notification.amount
      }
    })
  }
  return notifications
}
