import axios from 'axios'

export function deleteImportantNotification ({ commit }) {
  commit('DELETE_IMPORTANT_NOTIFICATION')
  axios.delete('/api/messaging/v1/notifications/important')
    .then((response) => {
      commit('DELETED_IMPORTANT_NOTIFICATION', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}

export function getImportantNotification ({ commit }) {
  commit('GET_IMPORTANT_NOTIFICATION')
  return axios.get('/api/messaging/v1/notifications/important')
    .then((response) => {
      commit('RECEIVE_IMPORTANT_NOTIFICATION', response.data)
      return response
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}

export function storeImportantNotification ({ commit }, importantNotification) {
  commit('STORE_IMPORTANT_NOTIFICATION')
  axios.post('/api/messaging/v1/notifications/important', { important_notification: importantNotification })
    .then((response) => {
      commit('STORED_IMPORTANT_NOTIFICATION', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}
