export function STORE_IMPORTANT_NOTIFICATION (state) {
  state.importantNotification.submitting = true
}

export function STORED_IMPORTANT_NOTIFICATION (state, importantNotification) {
  state.importantNotification.data = importantNotification
  state.importantNotification.submitting = false
}

export function DELETE_IMPORTANT_NOTIFICATION (state) {
  state.importantNotification.data = {}
}

export function GET_IMPORTANT_NOTIFICATION (state) {
  state.importantNotification.fetching = true
}

export function RECEIVE_IMPORTANT_NOTIFICATION (state, importantNotification) {
  state.importantNotification = importantNotification
  state.importantNotification.fething = false
}
