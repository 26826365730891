import axios from 'axios'

export function notifications ({ commit }, { types }) {
  return new Promise((resolve, reject) => {
    commit('GET_NOTIFICATIONS')
    axios.get(`/api/messaging/v1/notifications/bubbles?types=${types}`)
      .then((response) => {
        commit('RECEIVE_NOTIFICATIONS', response.data)
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
