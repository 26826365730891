export default {
  methods: {
    async checkSubmit (observer, element, onSubmit) {
      const validationResult = await observer.validate()
      if (!validationResult) {
        this.afterSubmitFocus(element)
      } else if (onSubmit) {
        onSubmit()
      }
    },
    afterSubmitFocus (form) {
      const errorID = form.querySelector('[id].is-error').id
      if (!errorID) return
      let errorInput = form.querySelector(`[aria-describedby="${errorID}"]`)
      if (errorInput) {
        const tinymceRTE = form.querySelector(`[aria-describedby="${errorID}"] + .tox-tinymce`)
        errorInput = tinymceRTE || errorInput
        errorInput.focus()
        errorInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }
}
