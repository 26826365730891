<template>
  <ValidationObserver ref="observer">
    <form @submit.prevent="checkSubmit($refs.observer, $el, onSubmit)">
      <div class="columns">
        <div class="column">
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.data_protection_officer_email')" rules="required|email|max:255">
              <label for="school-form-data_protection_officer_email" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.data_protection_officer_email') }}
              </label>
              <div class="control">
                <input
                  id="school-form-data_protection_officer-email"
                  v-model="schoolData.data_protection_officer_email"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.data_protection_officer_email')"
                  data-qa="school-form-data_protection_officer-email-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-data_protection_officer-email-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-data_protection_officer-email-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-data_protection_officer-email-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.data_protection_officer_first_name')" rules="required|max:255">
              <label for="school-form-data_protection_officer_first_name" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.data_protection_officer_first_name') }}
              </label>
              <div class="control">
                <input
                  id="school-form-data_protection_officer-first-name"
                  v-model="schoolData.data_protection_officer_first_name"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.data_protection_officer_first_name')"
                  data-qa="school-form-data_protection_officer-first-name-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-data_protection_officer-first-name-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-data_protection_officer-first-name-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-data_protection_officer-first-name-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.data_protection_officer_last_name')" rules="required|max:255">
              <label for="school-form-data_protection_officer_last_name" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.data_protection_officer_last_name') }}
              </label>
              <div class="control">
                <input
                  id="school-form-data_protection_officer-last-name"
                  v-model="schoolData.data_protection_officer_last_name"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.data_protection_officer_last_name')"
                  data-qa="school-form-data_protection_officer-last-name-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-data_protection_officer-last-name-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-data_protection_officer-last-name-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-data_protection_officer-last-name-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.data_protection_officer_phone')" :rules="{ regex: phoneRegex, max: 20 }">
              <label for="school-form-data_protection_officer_phone" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.data_protection_officer_phone') }}
              </label>
              <div class="control">
                <input
                  id="school-form-data_protection_officer-phone"
                  v-model="schoolData.data_protection_officer_phone"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.data_protection_officer_phone')"
                  data-qa="school-form-data_protection_officer-phone-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-data_protection_officer-phone-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-data_protection_officer-phone-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-data_protection_officer-phone-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.principal_email')" rules="required|email|max:255">
              <label for="school-form-principal_email" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.principal_email') }}
              </label>
              <div class="control">
                <input
                  id="school-form-principal-email"
                  v-model="schoolData.principal_email"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.principal_email')"
                  data-qa="school-form-principal-email-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-principal-email-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-principal-email-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-principal-email-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.principal_first_name')" rules="required|max:255">
              <label for="school-form-principal_first_name" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.principal_first_name') }}
              </label>
              <div class="control">
                <input
                  id="school-form-principal-first-name"
                  v-model="schoolData.principal_first_name"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.principal_first_name')"
                  data-qa="school-form-principal-first-name-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-principal-first-name-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-principal-first-name-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-principal-first-name-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="field">
            <ValidationProvider v-slot="{ errors, validated, valid }" :name="$t('schulen.principal_last_name')" rules="required|max:255">
              <label for="school-form-principal_last_name" class="label" :class="{ 'has-text-danger': validated && !valid }">
                {{ $t('schulen.principal_last_name') }}
              </label>
              <div class="control">
                <input
                  id="school-form-principal-last-name"
                  v-model="schoolData.principal_last_name"
                  type="text"
                  class="input is-primary is-rounded"
                  :class="{ 'is-danger': validated && !valid }"
                  :placeholder="$t('schulen.principal_last_name')"
                  data-qa="school-form-principal-last-name-input"
                  :aria-invalid="!valid"
                  :aria-describedBy="(!valid && validated) && 'school-form-principal-last-name-error'"
                  aria-required="true"
                >
                <div
                  v-if="!valid && validated"
                  id="school-form-principal-last-name-error"
                  class="is-error has-text-danger"
                  data-qa="school-form-principal-last-name-error"
                  role="alert"
                >
                  <span class="icon is-small">
                    <i class="fas fa-exclamation" aria-hidden="true" />
                  </span>
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import checkSubmit from '@/mixins/checkSubmit'
import axios from 'axios'

export default {
  name: 'SchoolFormSmall',
  mixins: [checkSubmit],
  props: {
    schoolUuid: {
      type: String,
      required: true
    },
    initialSchoolData: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.schoolData = { ...this.initialSchoolData }
  },
  data () {
    return {
      schoolData: {
        data_protection_officer_email: '',
        data_protection_officer_first_name: '',
        data_protection_officer_last_name: '',
        data_protection_officer_phone: '',
        principal_email: '',
        principal_first_name: '',
        principal_last_name: ''
      },
      // eslint-disable-next-line
      phoneRegex: /(\(?([\d \-)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/
    }
  },
  methods: {
    // We are not using the store there because it's a global component
    onSubmit () {
      axios.patch(`/api/identity/v1/schools/${this.schoolUuid}`, this.schoolData)
        .then(() => {
          this.$emit('saved')
        })
        .catch((err) => {
          const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
          console.log(msg)
        })
    },
    submit () {
      this.checkSubmit(this.$refs.observer, this.$el, this.onSubmit)
    }
  }
}
</script>
