export default {
  achievements: {
    fetching: false,
    fetched: false,
    data: null
  },
  ranking: {
    fetching: false,
    fetched: false,
    data: {
      points: 0
    }
  },
  error: null
}
