import axios from 'axios'

export function me ({ commit }, reloadOnError = true) {
  return new Promise((resolve, reject) => {
    commit('GET_ME')
    axios.get('/api/identity/v1/users/me')
      .then((response) => {
        commit('RECEIVE_ME', response.data)
        resolve()
      })
      .catch(err => {
        if (reloadOnError) {
          localStorage.removeItem('token')
          location.reload()
        }
        reject(err)
      })
  })
}

export function toggleOnboarding ({ commit }) {
  return new Promise((resolve, reject) => {
    axios.patch('/api/identity/v1/users/me/onboarding')
      .then((response) => {
        commit('TOGGLE_ONBOARDING', response.data)
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function generateReactivationCode ({ commit }) {
  return new Promise((resolve, reject) => {
    axios.patch('/api/identity/v1/users/me/reactivation-code')
      .then((response) => {
        commit('GENERATE_REACTIVATION_CODE', response.data)
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}
