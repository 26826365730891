var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkSubmit(_vm.$refs.observer, _vm.$el, _vm.onSubmit)}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.data_protection_officer_email'),"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-data_protection_officer_email"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.data_protection_officer_email'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.data_protection_officer_email),expression:"schoolData.data_protection_officer_email"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-data_protection_officer-email","type":"text","placeholder":_vm.$t('schulen.data_protection_officer_email'),"data-qa":"school-form-data_protection_officer-email-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-data_protection_officer-email-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.data_protection_officer_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "data_protection_officer_email", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-data_protection_officer-email-error","data-qa":"school-form-data_protection_officer-email-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.data_protection_officer_first_name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-data_protection_officer_first_name"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.data_protection_officer_first_name'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.data_protection_officer_first_name),expression:"schoolData.data_protection_officer_first_name"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-data_protection_officer-first-name","type":"text","placeholder":_vm.$t('schulen.data_protection_officer_first_name'),"data-qa":"school-form-data_protection_officer-first-name-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-data_protection_officer-first-name-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.data_protection_officer_first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "data_protection_officer_first_name", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-data_protection_officer-first-name-error","data-qa":"school-form-data_protection_officer-first-name-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.data_protection_officer_last_name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-data_protection_officer_last_name"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.data_protection_officer_last_name'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.data_protection_officer_last_name),expression:"schoolData.data_protection_officer_last_name"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-data_protection_officer-last-name","type":"text","placeholder":_vm.$t('schulen.data_protection_officer_last_name'),"data-qa":"school-form-data_protection_officer-last-name-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-data_protection_officer-last-name-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.data_protection_officer_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "data_protection_officer_last_name", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-data_protection_officer-last-name-error","data-qa":"school-form-data_protection_officer-last-name-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.data_protection_officer_phone'),"rules":{ regex: _vm.phoneRegex, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-data_protection_officer_phone"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.data_protection_officer_phone'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.data_protection_officer_phone),expression:"schoolData.data_protection_officer_phone"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-data_protection_officer-phone","type":"text","placeholder":_vm.$t('schulen.data_protection_officer_phone'),"data-qa":"school-form-data_protection_officer-phone-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-data_protection_officer-phone-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.data_protection_officer_phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "data_protection_officer_phone", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-data_protection_officer-phone-error","data-qa":"school-form-data_protection_officer-phone-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.principal_email'),"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-principal_email"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.principal_email'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.principal_email),expression:"schoolData.principal_email"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-principal-email","type":"text","placeholder":_vm.$t('schulen.principal_email'),"data-qa":"school-form-principal-email-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-principal-email-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.principal_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "principal_email", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-principal-email-error","data-qa":"school-form-principal-email-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.principal_first_name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-principal_first_name"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.principal_first_name'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.principal_first_name),expression:"schoolData.principal_first_name"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-principal-first-name","type":"text","placeholder":_vm.$t('schulen.principal_first_name'),"data-qa":"school-form-principal-first-name-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-principal-first-name-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.principal_first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "principal_first_name", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-principal-first-name-error","data-qa":"school-form-principal-first-name-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1),_c('div',{staticClass:"field"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('schulen.principal_last_name'),"rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validated = ref.validated;
var valid = ref.valid;
return [_c('label',{staticClass:"label",class:{ 'has-text-danger': validated && !valid },attrs:{"for":"school-form-principal_last_name"}},[_vm._v(" "+_vm._s(_vm.$t('schulen.principal_last_name'))+" ")]),_c('div',{staticClass:"control"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.schoolData.principal_last_name),expression:"schoolData.principal_last_name"}],staticClass:"input is-primary is-rounded",class:{ 'is-danger': validated && !valid },attrs:{"id":"school-form-principal-last-name","type":"text","placeholder":_vm.$t('schulen.principal_last_name'),"data-qa":"school-form-principal-last-name-input","aria-invalid":!valid,"aria-describedBy":(!valid && validated) && 'school-form-principal-last-name-error',"aria-required":"true"},domProps:{"value":(_vm.schoolData.principal_last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.schoolData, "principal_last_name", $event.target.value)}}}),(!valid && validated)?_c('div',{staticClass:"is-error has-text-danger",attrs:{"id":"school-form-principal-last-name-error","data-qa":"school-form-principal-last-name-error","role":"alert"}},[_c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-exclamation",attrs:{"aria-hidden":"true"}})]),_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }