export function STORE_EVENTS (state) {
  state.events.submitting = true
  state.events.submitted = false
}

export function EVENTS_STORED (state) {
  state.events.submitting = false
  state.events.submitted = true
}

export function GET_EVENTS (state) {
  state.events.fetching = true
  state.events.fetched = false
}

export function RECEIVE_EVENTS (state, { events, start }) {
  state.events.fetching = false
  state.events.fetched = true

  if (start === 0 || !state.events.data) {
    state.events.data = events
  } else {
    state.events.data.has_more = events.has_more
    state.events.data.quota_max = events.quota_max
    state.events.data.quota_remaining = events.quota_remaining
    state.events.data.items.push(...events.items)
  }
}

export function EVENTS_ERROR (state, err) {
  if (state.events.submitting) state.events.submitting = false
  if (state.events.fetching) state.events.fetching = false
}
