import axios from 'axios'

export function storeNews ({ commit }, news) {
  return new Promise((resolve, reject) => {
    commit('STORE_NEWS')
    axios.post('/api/messaging/v1/news', news)
      .then(response => {
        commit('NEWS_STORED', response.data)
        resolve()
      }).catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('NEWS_ERROR', msg)
        reject(err)
      })
  })
}

export function getNews ({ commit }, { start = 0, limit = 10, filter = 'stufe:*' }) {
  return new Promise((resolve, reject) => {
    commit('GET_NEWS')
    axios.get(`/api/messaging/v1/news?filter=${filter}&start=${start}&limit=${limit}`)
      .then(response => {
        commit('RECEIVE_STORED', { news: response.data, start })
        resolve()
      }).catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('NEWS_ERROR', msg)
        reject(err)
      })
  })
}
