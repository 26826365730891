export function GET_ME (state) {
  state.me.fetching = true
  state.me.fetched = false
  state.error = null
}

export function RECEIVE_ME (state, me) {
  state.me.fetching = false
  state.me.fetched = true
  state.me.data = me
}

export function SET_ROLES (state, roles) {
  state.me.roles = roles
}

export function USERS_ERROR (state, err) {
  if (state.me.submitting) state.me.submitting = false
  if (state.me.fetching) state.me.fetching = false
  state.error = err
}

export function TOGGLE_ONBOARDING (state, onboarding) {
  state.me.data.show_onboarding = !state.me.data.show_onboarding
}

export function GENERATE_REACTIVATION_CODE (state, code) {
  state.me.data.reactivation_code = code
  state.me.data.reactivation_code_date = new Date().setDate(new Date().getDate() + 91)
}
