export function GET_WAY (state) {
  state.myway.fetching = true
  state.myway.fetched = false
  state.error = null
}

export function RECEIVE_WAY (state, career) {
  state.myway.fetching = false
  state.myway.fetched = true
  state.myway.data = career
  state.error = null
}

export function CAREER_ERROR (state, err) {
  if (state.myway.fetching) state.myway.fetching = false
  state.error = err
}

export function GET_MY_STATIONS (state) {
  state.stations.fetching = true
  state.stations.fetched = false
  state.error = null
}

export function RECEIVE_MY_STATIONS (state, stations) {
  state.stations.fetching = false
  state.stations.fetched = true
  state.stations.data = stations
  state.error = null
}
