import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { ValidationObserver, ValidationProvider, extend, localize, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import de from '@/i18n/validation/de'
import { i18n } from '@/i18n'
import Keycloak from 'keycloak-js'
import axios from 'axios'
import PortalVue from 'portal-vue'
import { clickOutside } from './directives'
import Menu from '@sunzinet-org/bwp-global-navigation'
import Messages from '@sunzinet-org/bwp-global-messaging'
import {
  Modal, Dropdown, FeedbackModal, Errors, Explanation, Select, FileInput, Attachment, Recorder, MultiProgress,
  ExternalLinkProceed, DropdownList, Onboarding, OnboardingModal, OfflineBanner, TermConditionModal, FullStorageModal
} from '@sunzinet-org/bwp-global-components'
import linkify from 'vue-linkify'
import VueTour from 'vue-tour'
import VueSanitize from 'vue-sanitize'

Vue.use(Menu)
Vue.use(Messages)
Vue.use(PortalVue)
Vue.use(VueTour)
Vue.use(VueSanitize)

Vue.config.productionTip = false

localize('de_DE', de)

// install custom directives
Vue.directive('click-outside', clickOutside)
Vue.directive('linkified', linkify)

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('Modal', Modal)
Vue.component('Dropdown', Dropdown)
Vue.component('FeedbackModal', FeedbackModal)
Vue.component('Errors', Errors)
Vue.component('Select', Select)
Vue.component('FileInput', FileInput)
Vue.component('Attachment', Attachment)
Vue.component('Explanation', Explanation)
Vue.component('Recorder', Recorder)
Vue.component('MultiProgress', MultiProgress)
Vue.component('ExternalLinkProceed', ExternalLinkProceed)
Vue.component('DropdownList', DropdownList)
Vue.component('Onboarding', Onboarding)
Vue.component('OnboardingModal', OnboardingModal)
Vue.component('OfflineBanner', OfflineBanner)
Vue.component('TermConditionModal', TermConditionModal)
Vue.component('FullStorageModal', FullStorageModal)
setInteractionMode('aggressive')

if (window.Cypress) {
  sessionStorage.setItem('cypress', 'true')
  sessionStorage.setItem('messagesManagement', window.Cypress?.env('messagesManagement'))
}

// delete keycloak.json from browser cache storage
if (window.caches) {
  window.caches.keys().then(function (names) {
    for (const name of names) {
      window.caches.open(name).then(function (cache) {
        cache.keys().then(function (keys) {
          keys.forEach(function (request, index, array) {
            if (request.url.includes('keycloak.json')) {
              cache.delete(request).then(r => {
              })
            }
          })
        })
      })
    }
  })
}

fetch(process.env.BASE_URL + 'config/keycloak.json', { cache: 'no-store' })
  .then((response) => response.json())
  .then(async function (keycloakConfig) {
    try {
      const keycloak = new Keycloak(keycloakConfig)
      if (window.location.pathname !== '/logout') {
        const authenticated = await keycloak.init({ onLoad: keycloakConfig.onLoad, checkLoginIframe: false })
        console.debug(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`)
        if (!authenticated) {
          window.location.reload()
        }
        // Add a request interceptor
        axios.interceptors.request.use(
          async function (config) {
            await keycloak.updateToken(10)
            config.headers.Authorization = 'Bearer ' + keycloak.token
            return config
          },
          error => {
            Promise.reject(error)
          })

        fetch(process.env.BASE_URL + 'config/matomo.json')
          .then((response) => response.json())
          .then((config) => {
            Vue.prototype.$matomo = config
          })
          .catch((error) => {
            console.debug(error)
          })
      }
      const roles = []
      if (keycloak.realmAccess?.roles) {
        keycloak.realmAccess?.roles.forEach((role) => {
          if (role.match(/^[A-Z|_]*$/)) {
            roles.push(role)
          }
        })
      }
      localStorage.setItem('userGroups', JSON.stringify(roles))
      Vue.prototype.$keycloak = keycloakConfig
      Vue.prototype.$keycloakClient = keycloak

      if (window.location.pathname !== '/logout') {
        // Check signatures
        const resp = await axios.get('/api/signatures/v1/flow/next-uuid')
        if (resp.data !== '') {
          const host = window.location.host
          window.location.href = `${window.location.protocol}//${host}/slp/${resp.data}`
        }
      }

      new Vue({
        router,
        store,
        i18n,
        created () {
          this.$store.commit('usersModule/SET_ROLES', JSON.parse(localStorage.getItem('userGroups')))
        },
        render: h => h(App)
      }).$mount('#app')
    } catch (error) {
      console.error('Failed to initialize adapter:', error)
    }
  })
