export function GET_RANKING (state) {
  state.ranking.fetching = true
  state.ranking.fetched = false
  state.error = null
}

export function RECEIVE_RANKING (state, ranking) {
  state.ranking.fetching = false
  state.ranking.fetched = true
  state.ranking.data = ranking
}

export function GET_ACHIEVEMENTS (state) {
  state.achievements.fetching = true
  state.achievements.fetched = false
  state.error = null
}

export function RECEIVE_ACHIEVEMENTS (state, achievements) {
  state.achievements.fetching = false
  state.achievements.fetched = true
  state.achievements.data = achievements
}
