import axios from 'axios'

export function getStations ({ commit }) {
  commit('GET_STATIONS')
  axios.get('/api/bo/v1/stations')
    .then(response => {
      commit('RECEIVE_STATIONS', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}

export function getStation ({ commit }) {
  commit('GET_STATION')
  axios.get('/api/bo/v1/stations/active')
    .then(response => {
      commit('RECEIVE_STATION', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}

export function getClassActiveStation ({ commit }, uuid) {
  commit('GET_STATION')
  axios.get('/api/bo/v1/stations/active/' + uuid)
    .then(response => {
      commit('RECEIVE_STATION', response.data)
    })
    .catch(err => {
      const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
      commit('STATION_ERROR', msg)
    })
}

export function activeStation ({ commit }, { classUuid, stationUuid }) {
  return new Promise((resolve, reject) => {
    commit('ACTIVE_STATION')
    axios.patch('/api/bo/v1/stations/active/' + classUuid + '/' + stationUuid)
      .then(() => {
        commit('STATION_ACTIVATED')
        resolve()
      })
      .catch(err => {
        const msg = !err ? 'Unbekannter Fehler' : err.response.status + ' - ' + err.response.statusText + ': ' + err.response.data
        commit('STATION_ERROR', msg)
        reject(err)
      })
  })
}
