export default {
  deadlines: {
    fetching: false,
    fetched: false,
    submitting: false,
    submitted: false,
    data: null
  },
  status: {
    fetching: false,
    fetched: false,
    data: {}
  }
}
