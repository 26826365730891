import de from 'vee-validate/dist/locale/de.json'

de.messages = {
  ...de.messages,
  ...{
    size: (_, values) => `${values._field_} muss kleiner als ${values.size / 1000}MB sein`
  }
}

export default de
